import { render, staticRenderFns } from "./index.vue?vue&type=template&id=b9d9b536&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=b9d9b536&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9d9b536",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeading: require('/opt/build/repo/components/base/BaseHeading.vue').default,BaseText: require('/opt/build/repo/components/base/BaseText.vue').default})
